import React, { Children, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Accent from '../Inlines/Accent.js'
import StepperInput from '../Forms/StepperInput'
import DurationInput from '../Forms/DurationInput'
import CheckboxInput from '../Forms/CheckboxInput'
import EstimationModal from '../Modals/EstimationModal'
import { isNumeric } from '../utils'
import ModalButton from '../Modals/ModalButton'
import { ModalContextProvider } from '../Modals/Modal'

import styles from './project-estimation.module.sass'

const InputTypeSwitch = props => {
  switch (props.type) {
    case 'stepper':
      return <StepperInput {...props} />
    case 'duration':
      return <DurationInput {...props} />
    case 'checkbox':
      return <CheckboxInput {...props} />
    default:
      return (
        <p className={styles.inputItem}>
          <input type={props.type} name={props.name} id={props.name} />
          <label htmlFor={props.name} className={styles.inputLabel}>
            {props.label || props.name}
          </label>
        </p>
      )
  }
}

const ConditionalGroup = ({ condition, children, groupTitle }) => {
  return condition ? (
    <div className={classNames(styles.inputGroup, { [styles.moreItems]: children.props.children.length > 4 })}>
      <strong className={styles.groupTitle}>{groupTitle}</strong>
      {children}
    </div>
  ) : (
    <>{children}</>
  )
}

export default function ProjectEstimation({ content, estimation }) {
  const [data, setData] = useState({ subject: '', name: '', email: '', message: '', duration: '1 month' })

  return (
    <div className={classNames(styles.projectForm)}>
      <Markdown className={styles.estimationContent} options={{ overrides: { Accent } }}>
        {content || ''}
      </Markdown>

      <div className={styles.estimationFields}>
        {estimation.fields.map((field, i) => (
          <div key={i} className={classNames(styles.estimationField, field.classes)}>
            <h3 className={styles.fieldTitle}>{field.title}</h3>
            {field.inputs ? (
              Object.values(field.inputs).map((input, i) => (
                <ConditionalGroup key={i} condition={typeof input === 'object'} groupTitle={Object.keys(field.inputs).find(key => field.inputs[key] === input)}>
                  {
                    {
                      object: (
                        <>
                          {Object.keys(input).map(key => {
                            var label = isNumeric(key) ? input[key] : key
                            return (
                              <InputTypeSwitch
                                key={key}
                                type={field.type}
                                label={label}
                                name={field.name}
                                input={isNumeric(key) ? input[key] : key}
                                data={data}
                                setData={setData}
                              />
                            )
                          })}
                        </>
                      ),
                      string: <InputTypeSwitch key={i} type={field.type} label={input} name={field.name} input={input} data={data} setData={setData} />
                    }[typeof input]
                  }
                </ConditionalGroup>
              ))
            ) : (
              <InputTypeSwitch type={field.type} label={field.input} name={field.name} input={field.input} data={data} setData={setData} />
            )}
          </div>
        ))}
      </div>
      {/* <p>{JSON.stringify(data)}</p> */}

      <ModalContextProvider>
        <ModalButton className={styles.modalButton} name={estimation.name} arrow>
          Get pricing
        </ModalButton>

        <EstimationModal estimation={estimation} data={data} setData={setData} />
      </ModalContextProvider>
    </div>
  )
}

// (
//   <div>string input</div>
// ) : (
//   <div>
//     <strong>{key}</strong>
//     <p>{typeof field.inputs[key]}</p>
//     <p>{JSON.stringify(field.inputs[key])}</p>
//   </div>
// )
