import React from 'react'

import pathToAlt from '../utils/path-to-alt'

export default ({ images }) => (
  <div className="image-line">
    {(images || []).map((image, index) => (
      <img
        alt={pathToAlt(image)}
        src={image}
        style={{ width: '33.33%' }}
        key={`image-${index}`}
      />
    ))}
  </div>
)
