import React from 'react'
import classNames from 'classnames'

import styles from './checkbox-input.module.sass'

export default function CheckboxInput({ label, name, input, data, setData }) {
  const handleChange = e => {
    const { checked } = e.target

    setData(current => {
      if (checked) {
        return { ...current, [name]: current[name] ? current[name].concat(', ', input) : input }
      } else {
        return {
          ...current,
          [name]: current[name]
            .split(', ')
            .filter(s => s !== input)
            .join(', ')
        }
      }
    })
  }

  return (
    <p className={styles.checkboxInput}>
      <input type="checkbox" name={name} id={`${name}[${input}]`} value={input} onChange={handleChange} />
      <label htmlFor={`${name}[${input}]`} className={styles.inputLabel}>
        {label}
      </label>
      {/* <p>{JSON.stringify({ label, name, input })}</p> */}
    </p>
  )
}
