import React from 'react'
// import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'

import classNames from 'classnames'
import styles from './stepper-input.module.sass'

export default function StepperInput({ input, name, label, info, data, setData }) {
  // console.log(name, input)

  const increment = () => {
    setData(current => ({ ...current, [name]: Object.assign(get(current, name, {}), { [input]: get(current, [name, input], 0) + 1 }) }))
  }

  const decrement = () => {
    setData(current => ({ ...current, [name]: Object.assign(get(current, name, {}), { [input]: get(current, [name, input], 1) - 1 }) }))
  }

  return (
    <p className={styles.stepperInput}>
      {/* <p>{JSON.stringify({ label, name, input })}</p> */}
      <button className={styles.stepperDecrement} aria-label="Minus" onClick={decrement} disabled={get(data, [name, input], 0) < 1}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor">
          <path fill="currentColor" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10ZM5 9v2h10V9H5Z" />
        </svg>
      </button>
      <input type="text" className={styles.stepperValue} name={name} value={get(data, [name, input], 0)} disabled />
      <button
        className={classNames(styles.stepperIncrement, { [styles.active]: get(data, [name, input], 0) > 0 })}
        aria-label="Plus"
        onClick={increment}
        disabled={get(data, [name, input], 0) > 8}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor">
          <path fill="currentColor" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10ZM9 9H5v2h4v4h2v-4h4V9h-4V5H9v4Z" />
        </svg>
      </button>
      <label
        className={classNames(styles.stepperLabel, { [styles.active]: get(data, [name, input], 0) > 0 })}
        onClick={increment}
        disabled={get(data, [name, input], 0) > 8}
      >
        <span>{label}</span>
        {info && <span>{info}</span>}
      </label>
    </p>
  )
}
