import React from 'react'

export default function Person({ className, name, position }) {
  return (
    <div className={className}>
      <strong>{name}</strong>
      <span>{position}</span>
    </div>
  )
}
