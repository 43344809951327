import React from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Image from '../Image'
import Button from '../Inlines/Button'
import ImageFlex from '../Blocks/ImageFlex'
import Person from '../Blocks/Person'
import { placeholder, pathToAlt } from '../utils'

import styles from './service-group.module.sass'

const Service = ({ children }) => <div className={styles.serviceItem}>{children}</div>

export default function ServiceGroup({ content, cta, image, customClass }) {
  return (
    <div className={classNames(styles.serviceGroup, customClass)}>
      {content && (
        <Markdown
          className={classNames(styles.serviceContent, 'service-content')}
          children={content}
          options={{
            forceBlock: true,
            forceWrapper: true,
            overrides: {
              ImageFlex,
              Service,
              img: {
                component: Image,
                props: { wrapper: true }
              }
            }
          }}
        />
      )}
      <figure className={classNames(styles.serviceImage, 'service-image')}>
        {image && <Image src={placeholder(935, 670)} data-flickity-lazyload={image} alt={pathToAlt(image)} />}
        {cta && (
          <Markdown
            className={classNames(styles.serviceCta, 'service-cta')}
            children={cta}
            options={{
              forceBlock: true,
              forceWrapper: true,
              wrapper: 'figcaption',
              overrides: {
                Button,
                Person: {
                  component: Person,
                  props: { className: styles.servicePerson }
                }
              }
            }}
          />
        )}
      </figure>
    </div>
  )
}
