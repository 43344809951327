import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Flickity from 'react-flickity-component'
import styles from './page-tabs.module.sass'

export default function PageTabs({ tabs }) {
  const navInstance = useRef(null)
  const ref = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    document.querySelector(':root').style.setProperty('--tabsHeight', ref.current.clientHeight + 'px')

    const flkty = navInstance.current
    const resize = navInstance.current.resize
    flkty.resize = () => {
      flkty.element.classList.add(`flickity-resize`)
      resize.call(flkty)
      flkty.element.classList.remove(`flickity-resize`)
    }

    const index = tabs.findIndex(tab => tab.includes(`(${window.location.pathname})`))
    setSelectedIndex(index)
  }, [tabs])

  return (
    <section ref={ref} className={styles.pageTabs}>
      <div className="container">
        <div className="row">
          <Flickity
            flickityRef={ref => {
              navInstance.current = ref
            }}
            className={styles.pageNav}
            options={{
              contain: true,
              cellAlign: 'left',
              pageDots: false,
              prevNextButtons: false,
              selectedAttraction: 0.3,
              friction: 0.8,
              initialIndex: selectedIndex
            }}
            reloadOnUpdate
            static
          >
            {tabs.map((tab, i) => {
              return (
                <Markdown key={i} className={classNames(styles.pageTab, 'col', { 'is-selected': i === selectedIndex })}>
                  {tab}
                </Markdown>
              )
            })}
          </Flickity>
        </div>
      </div>
    </section>
  )
}
