import React from 'react'
import loadable from '@loadable/component'

import { Link } from 'gatsby'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'

import FeaturedIn from './FeaturedIn'
import HeroNav from '../HeroNav/HeroNav'
import LinkOrDiv from '../LinkOrDiv'

// import Calendly from '../Widgets/Calendly'
const LazyCalendly = loadable(() => import('../Widgets/Calendly'))
const Calendly = props => <LazyCalendly {...props} />
// import ContactForm from '../ContactForm/ContactForm'
const LazyContactForm = loadable(() => import('../ContactForm/ContactForm'))
const ContactForm = props => <LazyContactForm {...props} />

// import Subscribe from '../Subscribe';
// import YouTube from './YouTube';

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {faReact, faNodeJs} from '@fortawesome/free-brands-svg-icons';
// import {
//   faMagic,
//   faMobileAlt,
//   faRunning,
//   faCode,
//   faThumbsUp,
// } from '@fortawesome/free-solid-svg-icons';
//
// library.add(
//   faCode,
//   faMobileAlt,
//   faReact,
//   faRunning,
//   faMagic,
//   faNodeJs,
//   faThumbsUp,
// );

const Button = ({ to, outline, children }) => (
  <Link exact="true" to={to} prefetch={false} className={classNames(`btn btn-lg btn${outline ? '-outline' : ''}-primary`)}>
    {children}
  </Link>
)

const Row = ({ bg, center, children }) => (
  <div
    className={classNames('row align-middle p-sm-1 p-md-5', {
      'text-center': center,
      'bg-inverse text-white': bg
    })}
    style={{ backgroundImage: `url(${bg})` }}
  >
    {children}
  </div>
)

const Col = col => ({ bg, url, children }) => (
  <LinkOrDiv
    url={url}
    className={classNames(`col-lg-${col} col-md-${col === 2 ? '6' : '12'} p-3`, {
      'bg-inverse text-white': bg
    })}
    style={{ backgroundImage: `url(${bg})` }}
  >
    {children}
  </LinkOrDiv>
)

const LinkedInIcon = ({ link }) => (
  <a href={link} target="_blank" style={{ display: 'inline-block', margin: '.25rem 0 0 1rem' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" style={{ width: '24px', height: '24px', display: 'block' }}>
      <path
        d="M365 1414h231V720H365v694zm246-908q-1-52-36-86t-93-34-94.5 34-36.5 86q0 51 35.5 85.5T479 626h1q59 0 95-34.5t36-85.5zm585 908h231v-398q0-154-73-233t-193-79q-136 0-209 117h2V720H723q3 66 0 694h231v-388q0-38 7-56 15-35 45-59.5t74-24.5q116 0 116 157v371zm468-998v960q0 119-84.5 203.5T1376 1664H416q-119 0-203.5-84.5T128 1376V416q0-119 84.5-203.5T416 128h960q119 0 203.5 84.5T1664 416z"
        fill="#0078B6"
      />
    </svg>
  </a>
)

export default ({ children }) => (
  <Markdown
    options={{
      forceBlock: true,
      overrides: {
        Button,
        Col2: Col(2),
        Col4: Col(4),
        Col6: Col(6),
        Col9: Col(9),
        Col12: Col(12),
        Row,
        FeaturedIn,
        HeroNav,
        ContactForm,
        Calendly,
        LinkedInIcon
      }
    }}
    children={children || ''}
  />
)
