import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Markdown from 'markdown-to-jsx'
import Flickity from 'react-flickity-component'
// import 'flickity/css/flickity.css'
import classNames from 'classnames'
import styles from './job-carousel.module.sass'

import JobCard from '../CardItems/JobCard'
import Filters from '../Blocks/Filters'
import Accent from '../Inlines/Accent'

import lowerCase from 'lodash/lowerCase'

function slidesVisible(elements) {
  elements.forEach(element => {
    if (typeof element.getBoundingClientRect !== 'function') {
      return
    }
    var rect = element.getBoundingClientRect()
    var visible = rect.x >= 0 && rect.x + rect.width <= (window.innerWidth || document.documentElement.clientWidth)
    element.classList.toggle('is-inactive', !visible)
  })
}

const jobsOptions = {
  pageDots: false,
  cellAlign: 'left',
  contain: true,
  selectedAttraction: 0.2,
  friction: 0.8,
  draggable: true,
  groupCells: true,
  on: {
    ready: function() {
      slidesVisible(this.cells.map(c => c.element))
      this.element.classList.toggle('buttons-disabled', this.element.querySelectorAll('.flickity-button[disabled]').length > 1)
    },
    scroll: function() {
      slidesVisible(this.cells.map(c => c.element))
    }
  }
}

export default function JobCarousel({ content, jobs, filters, classes }) {
  const jobsInstance = useRef(null)
  const [jobFilter, setTeamFilter] = useState(null)
  const matchTags = (current, target) => {
    return [target].filter(Boolean).every(tag => (current || []).includes(tag))
  }
  const data = useStaticQuery(graphql`
    query activeJobs {
      jobs: allJobPostsYaml(filter: { active: { ne: false } }) {
        nodes {
          path
          title
          description
          salary
          location
          technology
          icon
        }
      }
    }
  `)

  filters ||= [...new Set(data.jobs.nodes.map(job => job.technology))]

  useEffect(() => {
    const flkty = jobsInstance.current

    if (flkty) {
      const resize = flkty.resize
      flkty.resize = () => {
        flkty.element.classList.add(`flickity-resize`)
        resize.call(flkty)
        flkty.element.classList.remove(`flickity-resize`)
      }
    }
  }, [])

  if (data.jobs.nodes.length === 0) {
    return null
  }

  return (
    <section id="jobs" className={classNames(styles.carouselSection, classes)}>
      <div className="container">
        {content && <Markdown className={styles.sectionHeader} children={content} options={{ forceBlock: true, overrides: { Accent } }} />}

        {data.jobs.nodes.length > 2 && <Filters tags={filters} filter={jobFilter} setFilter={setTeamFilter} allTag="All" />}
        <Flickity
          flickityRef={carouselRef => {
            jobsInstance.current = carouselRef
          }}
          className={classNames(styles.jobList, styles.flickityCarousel)}
          elementType={'div'}
          options={jobsOptions}
        >
          {data.jobs.nodes
            .filter(job => lowerCase(job.technology) === lowerCase(jobFilter) || !jobFilter)
            .map((job, i) => (
              <JobCard key={i} {...job} />
            ))}
        </Flickity>
      </div>
    </section>
  )
}
