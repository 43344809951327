import React from 'react'
import classNames from 'classnames'
import PageContent from '../content/PageContent'

const ImageSection = ({ classes, background, center, text, blend }) => {
  return (
    <section
      className={classNames(`image ${classes}`, {
        '-center': center
      })}
    >
      <figure>
        {background && (
          <img
            alt={'Background'}
            className="image__background"
            src={background}
          />
        )}
        {background && blend && (
          <div className="image__blend" style={{ opacity: blend }} />
        )}
        <figcaption className="image__container">
          <div className="d-flex align-items-center">
            <figure>
              <div className="container">
                <PageContent children={text} />
              </div>
            </figure>
          </div>
        </figcaption>
      </figure>
    </section>
  )
}

export default ImageSection
