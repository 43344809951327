import React from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Image from '../Image'
import Button from '../Inlines/Button'
import ImageFlex from '../Blocks/ImageFlex'
import Person from '../Blocks/Person'

import styles from './service-block.module.sass'

export default function ServiceBlock({ content, cta, image, customClass, images }) {
  return (
    <div className={classNames(styles.serviceBlock, customClass)}>
      {content && (
        <Markdown
          className={classNames(styles.serviceContent, 'service-content')}
          children={content}
          options={{
            forceBlock: true,
            forceWrapper: true,
            overrides: {
              ImageFlex,
              img: {
                component: Image,
                props: { wrapper: true }
              },
              a: {
                props: {
                  className: styles.serviceLink
                }
              }
            }
          }}
        />
      )}
      <figure className={classNames(styles.serviceImage, 'service-image')}>
        {image && <Image src={image} images={images} />}
        {cta && (
          <Markdown
            className={classNames(styles.serviceCta, 'service-cta')}
            children={cta}
            options={{
              forceBlock: true,
              forceWrapper: true,
              overrides: {
                Button,
                Person: {
                  component: Person,
                  props: { className: styles.servicePerson }
                }
              }
            }}
          />
        )}
      </figure>
    </div>
  )
}
