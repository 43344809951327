import React, { useContext } from 'react'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'
import { ModalContext } from '../Modals/Modal'

export default function ModalButton({ children, name, className, arrow }) {
  const { setActiveModal, set } = useContext(ModalContext)

  const handleClick = e => {
    e.preventDefault()
    setActiveModal(set ? `${set}_${name}` : name)
  }
  return (
    <button className={className} onClick={handleClick} tabIndex={-1}>
      {children}
      {arrow && <ArrowIcon />}
    </button>
  )
}
