import React, { useState, useEffect, useRef } from 'react'
import Markdown, { compiler } from 'markdown-to-jsx'
import styles from './faq-section.module.sass'
import classNames from 'classnames'
import { parameterize, debounce } from '../utils/'
import ReactDOMServer from 'react-dom/server'
import Accent from '../Inlines/Accent'

function getTop(element) {
  return element && Math.floor(element.getBoundingClientRect().top + window.scrollY - parseInt(getComputedStyle(element).scrollMarginTop))
}

function isVisible(element) {
  return element && element.getBoundingClientRect().top >= 0 && element.getBoundingClientRect().bottom <= window.innerHeight
}

export default function FaqSection({ questions }) {
  const containerRef = useRef(null) // Ref to the container element
  const [activeQuestion, setActiveQuestion] = useState(0) // Set the default active question

  const faqStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map(question => ({
      '@type': 'Question',
      name: question.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: ReactDOMServer.renderToString(compiler(question.text, { wrapper: ({ children }) => children }))
      }
    }))
  }

  useEffect(() => {
    const handleScroll = debounce(() => {
      const questionElements = containerRef.current.querySelectorAll('article')

      questionElements.forEach((question, index) => {
        if (isVisible(question)) {
          isVisible(question.previousElementSibling) ? setActiveQuestion(index - 1) : setActiveQuestion(index)
        }
      })
    }, 9)

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // const handleNavLinkClick = questionId => {
  //   const questionElement = document.getElementById(`question-${questionId}`)
  //   questionElement.scrollIntoView({ behavior: 'smooth' })
  // }

  return (
    <section className={classNames(styles.faqSection, 'pt-3 pb-3')}>
      <div className="container">
        <div className="row">
          <div className={classNames(styles.sectionMain, 'col-lg-8 offset-xxl-1')}>
            <div className={styles.sectionHeader}>
              <h2>Frequently Asked Questions (FAQ)</h2>
              <Accent />
            </div>
            <div className={styles.questions} ref={containerRef}>
              {(questions || []).map((question, i) => (
                <article className={classNames(styles.question)} key={i} id={parameterize(question.title)} open>
                  <h3>{question.title}</h3>
                  {question.text && (
                    <Markdown
                      className={styles.answear}
                      children={question.text}
                      options={{
                        wrapper: ({ children }) => children
                      }}
                    />
                  )}
                </article>
              ))}
            </div>
          </div>
          <div className={classNames(styles.sectionAside, 'd-none d-lg-block mb-5 mb-lg-0 col-lg-4 col-xxl-3')}>
            <span className={styles.navLabel}>Contents</span>

            <ul className={styles.questionsNav}>
              {(questions || []).map((question, i) => (
                <li className={classNames(styles.navItem, i === activeQuestion && 'is-active')} key={i}>
                  <a className={styles.navLink} href={'#' + parameterize(question.title)} onClick={() => setActiveQuestion(i)}>
                    {question.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(faqStructuredData) }}
      />
    </section>
  )
}
