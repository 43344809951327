import React from 'react'
import styles from './service-section.module.sass'
import ServiceBlock from '../CardItems/ServiceBlock'

export default function ServiceSection({ content, image, cta, images, id }) {
  return (
    <section id={id} className={styles.serviceSection} style={{ backgroundColor: '#F8F9FA' }}>
      <div className="container position-relative">
        <ServiceBlock content={content} image={image} cta={cta} images={images} />
      </div>
    </section>
  )
}
