import React from 'react'
import Markdown from 'markdown-to-jsx'
import slugify from 'slugify'
import classNames from 'classnames'

import Button from '../Inlines/Button'
import Image from '../Image'

import styles from './job-card.module.sass'

export default function JobCard({ title, salary, location, path, description, icon }) {
  return (
    <div className={classNames(styles.jobCard, icon && styles.hasIcon)}>
      <h3 className={styles.jobTitle}>{title}</h3>
      <span className={styles.jobLocation}>{location}</span>
      <span className={styles.jobSalary}>{salary}</span>
      <p className={styles.jobDescrition}>{description}</p>
      <Button customClass={styles.jobButton} url={path} arrow>
        Learn more
      </Button>
      {icon && <Image className={styles.jobIcon} src={`/images/technologies/${slugify(icon, { lower: true })}.svg`} wrapper />}
    </div>
  )
}
