import React from 'react'
import styles from './numbers-section.module.sass'
import Numbers from '../Blocks/Numbers'

export default function NumbersSection({ classes, numbers }) {
  return (
    <section className={styles.numbersSection}>
      <div className="container">
        <Numbers numbers={numbers} />
      </div>
    </section>
  )
}
