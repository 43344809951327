import React, { useState, useEffect, useRef } from 'react'

import { navigate } from 'gatsby'
import { debounce } from '../utils'
import classNames from 'classnames'
import { services, getService, setService, getCalendlyLink } from '../Service.js'
import { isSpam } from '../../utils/tracking'
import ModalButton from '../Modals/ModalButton'
import CalendlyModal from '../Modals/CalendlyModal'
import Modal, { ModalContextProvider } from '../Modals/Modal'
import kebabCase from 'lodash/kebabCase'
import ProfileSlider from '../Blocks/ProfileSlider.js'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'

import Markdown from 'markdown-to-jsx'
import Image from '../Image'

import styles from './contact-expert.module.sass'

const PersonItem = ({ name, position, avatar }) => (
  <div className={styles.personItem}>
    <Image className={styles.personImage} src={avatar} alt={name} wrapper />
    <h3 className={styles.personName}>
      {name}
      <ModalButton className={styles.modalButton} name={kebabCase(name)} />
    </h3>
    <span className={styles.personTitle}>{position}</span>
  </div>
)

const ModalSet = ({ children, set }) => {
  return (
    <ModalContextProvider set={set}>
      {children}
      <Modal name={set}>
        <ProfileSlider>{children}</ProfileSlider>
      </Modal>
      {/* <ProfileSlider customClass="block">{children}</ProfileSlider> */}
    </ModalContextProvider>
  )
}

export default function ContactExpert({ id, title, content, bar }) {
  const ref = useRef(null)
  const [data, setData] = useState({ service: Object.keys(services)[0], subject: 'SoftKraft enquiry', path: '/contact/' })
  const [formState, setFormState] = useState('')
  const [barVisible, setBarVisible] = useState(false)
  const [formStarted, setFormStarted] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData({ ...data, path: [localStorage.entry, window.location.pathname].join(', ') })
    }
    const handleScroll = () => {
      // console.log(ref.current.getBoundingClientRect().bottom - 120)
      setBarVisible(ref.current.getBoundingClientRect().bottom - 120 < 0)
    }
    const debouncedHandleScroll = debounce(handleScroll, 1)
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => window.removeEventListener('scroll', debouncedHandleScroll)
  }, [])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (isSpam(data.message)) {
      navigate('/contact/submitted/')
    }

    const form = e.target
    setFormState('loading')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': form.getAttribute('name'),
        ...data,
        service: services[data.service]
      }).toString()
    })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'form_sent',
          formName: form.getAttribute('name'),
          email: data.email,
          service: services[data.service]
        })

        localStorage.removeItem('entry')
        setFormState('success')
        navigate(form.getAttribute('action'))
      })
      .catch(error => setFormState('error'))
  }

  const handleSelect = e => {
    setData({ ...data, service: setService(e.target.value) || data.service })
  }

  const handleInput = e => {
    handleChange(e)
    if (!formStarted) {
      setFormStarted(true)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'form_start',
        formName: 'Expert',
        service: services[data.service]
      })
    }
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  const handleName = e => {
    setData({ ...data, subject: `SoftKraft enquiry from ${e.target.value}`, name: e.target.value })
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  return (
    <section id={id || 'contact'} ref={ref} className={classNames(styles.contactExpert, 'profiles-bg pb-10')}>
      <div className="container">
        <h2 className={styles.sectionTitle}>
          <Markdown className={styles.sectionTitle} options={{ wrapper: 'h2' }}>
            {title}
          </Markdown>
        </h2>
        <div className={classNames(styles.expertBlock, 'row')}>
          <Markdown className={classNames(styles.blockContent, 'col-md-6')} options={{ forceWrapper: true, overrides: { PersonItem, ModalSet } }}>
            {content}
          </Markdown>
          <form
            name="Form"
            method="post"
            action="/contact/thank-you/"
            data-netlify="true"
            // data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className={classNames(styles.contactForm, styles[formState], 'col-md-6')}
          >
            <div>
              <p hidden>
                {/* <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label> */}
                <input type="hidden" name="form-name" value="Form" />
                <input type="hidden" name="subject" />
                <input type="hidden" name="path" />
              </p>
              {/* {formState === 'success' && <p>Thanks for contacting us!</p>} */}
              {formState === 'error' && <p>Sorry, something went wrong</p>}
              <p className={styles.formField}>
                <label htmlFor="name">Your name</label>
                <input type="text" name="name" id="name" required onChange={handleName} />
              </p>
              <p className={styles.formField}>
                <label htmlFor="email">Your email</label>
                <input type="email" name="email" id="email" required onChange={handleInput} />
              </p>
              <p className={styles.formField}>
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" required rows={10} onChange={handleInput} />
              </p>
              <p className={styles.formField}>
                <input type="checkbox" required id="privacy" />
                <label htmlFor="privacy">
                  I consent processing my personal data according to the{' '}
                  <a href="/privacy/" target="_blank">
                    Privacy&nbsp;Policy
                  </a>
                </label>
              </p>
              <p className={classNames(styles.formField, styles.fieldFlex)}>
                <button disabled={formState === 'loading'} className={styles.formSubmit}>
                  {formState === 'loading' ? 'Sending...' : 'Send'}
                </button>
                {getCalendlyLink(data.service) && (
                  <ModalContextProvider>
                    <span className={styles.formDivider}>or</span>

                    <ModalButton className={styles.formLink} name={'calendly-modal'}>
                      Schedule Consultation
                    </ModalButton>
                    <CalendlyModal url={getCalendlyLink(data.service)} />
                  </ModalContextProvider>
                )}
              </p>
            </div>
          </form>
        </div>
      </div>
      <a
        href="#contact"
        className={classNames(styles.contactBar, { 'is-visible': barVisible })}
        onClick={e => {
          e.preventDefault()
          document.scrollingElement.onClickScroll = true
          document.addEventListener(
            'scrollend',
            function() {
              document.scrollingElement.onClickScroll = false
            },
            { once: true }
          )
          document.getElementById('contact').scrollIntoView({
            behavior: 'smooth'
          })
        }}
      >
        <Markdown options={{ wrapper: false }}>{bar || 'Get a **free&nbsp;quote** for&nbsp;your project – Reach out today'}</Markdown>
      </a>
    </section>
  )
}

const PersonCard = ({ name, title, avatar, about, service }) => (
  <div className={styles.personCard}>
    <Image className={styles.personImage} src={avatar} alt={name} />
    <h3 className={styles.personName}>{name}</h3>
    <span className={styles.personTitle}>{title}</span>
    <p className={styles.personAbout}>{about}</p>
    <ModalContextProvider>
      <ModalButton className={styles.personButton} name={'calendly-modal'}>
        Schedule Consultation
      </ModalButton>
      <CalendlyModal url={getCalendlyLink(service)} />
    </ModalContextProvider>
  </div>
)
