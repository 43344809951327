import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Flickity from 'react-flickity-component'
import { isMobile } from 'react-device-detect'
import ServiceGroup from '../Blocks/ServiceGroup'
import { interpolate } from '../utils'
import styles from './block-slider.module.sass'
import { tabNav, tabCell } from '../Blocks/tab-nav.module.sass'

export default function BlockSlider({ blocks, classes }) {
  const sliderInstance = useRef(null)
  const navInstance = useRef(null)

  const sliderOptions = {
    pageDots: false,
    cellAlign: 'left',
    draggable: isMobile,
    selectedAttraction: 0.3, // 0.025
    friction: 0.8, // 0.28
    prevNextButtons: false,
    adaptiveHeight: true,
    lazyLoad: true
  }

  useEffect(() => {
    const nav = navInstance.current
    const flkty = sliderInstance.current

    if (!flkty) {
      return true
    }

    nav.on('change', index => {
      flkty.select(index)
    })

    nav.on('staticClick', function(event, pointer, cellElement, cellIndex) {
      if (cellElement) {
        document.querySelector('.flickity-enabled').scrollIntoView({
          behavior: 'smooth'
        })
        cellElement.classList.add('is-selected')
        nav.select(cellIndex)
      }
    })

    // let serviceTabs = document.querySelectorAll('.cell-tab')
    // // console.log(serviceTabs)
    // serviceTabs.forEach(tab => {
    //   tab.addEventListener('click', function(event) {
    //     event.stopPropagation()
    //     var tab = event.target
    //     var index = [...tab.parentNode.children].indexOf(tab)
    //     for (let sib of tab.parentNode.children) {
    //       sib.classList.remove('is-selected')
    //     }
    //     tab.classList.add('is-selected')
    //     flkty.select(index)
    //   })
    // })

    flkty.on('change', index => {
      nav.select(index)
    })

    let transformProp = typeof document.documentElement.style.transform == 'string' ? 'transform' : 'WebkitTransform'

    flkty.on('scroll', () => {
      flkty.slides.forEach(function(slide, i) {
        let cellElement = flkty.cells[i].element
        let serviceBlock = cellElement.querySelector('.service-block')
        let serviceCta = cellElement.querySelector('.service-cta')
        let previousWidth = (flkty.slides[i - 1] && flkty.slides[i - 1].outerWidth) || 0
        let x = slide.x + flkty.x
        let start = previousWidth || slide.outerWidth
        let end = 0

        let cellOpacity = interpolate(x, start, end, 0, 1)

        serviceBlock && (serviceBlock.style.opacity = cellOpacity)
        serviceCta && (serviceCta.style[transformProp] = `translateX(${flkty.x * -1 - slide.target}px)`)
      })
    })

    window.addEventListener('scroll', event => {
      // console.log(document.scrollingElement.scrollTop)
      let opacity = 100 - (flkty.element.getBoundingClientRect().top - 300) / 10
      for (let fig of flkty.element.querySelectorAll('figure')) {
        // console.log(opacity)
        fig.style.opacity = opacity >= 0 && opacity <= 100 ? opacity / 100 : 1
      }
    })
  }, [])

  return (
    <section className={classNames(styles.blockSlider, classes)}>
      {blocks && (
        <div className="container">
          <Flickity
            flickityRef={ref => {
              navInstance.current = ref
            }}
            className={tabNav}
            options={{
              contain: true,
              cellAlign: 'left',
              pageDots: false,
              prevNextButtons: false,
              selectedAttraction: 0.3,
              friction: 0.8
            }}
            reloadOnUpdate
            static
          >
            {blocks.map((group, i) => (
              <Markdown key={i} className={classNames(tabCell, 'cell-tab', { 'is-selected': i === 0 })}>
                {group.header}
              </Markdown>
            ))}
          </Flickity>

          <Flickity
            flickityRef={ref => {
              sliderInstance.current = ref
            }}
            className={classNames(styles.groupList, styles.flickityCarousel)}
            options={sliderOptions}
            reloadOnUpdate
            static
          >
            {blocks.map((group, i) => (
              <div key={i} className={classNames(styles.blockCell, 'flickity-cell')}>
                <ServiceGroup {...group} customClass="container service-block cell-block block-item" />
              </div>
            ))}
          </Flickity>
        </div>
      )}
    </section>
  )
}
