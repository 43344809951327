import React from 'react'

const featuredInLogos = ['entrepreneur', 'forbes', 'techadvisor', 'techcrunch']

export default () => (
  <div className="featured-in container">
    <div className="row d-flex align-content-center justify-content-between">
      <div className="text-large text-white">
        Our Clients' applications were featured in:
      </div>
      {featuredInLogos.map(logo => (
        <div key={logo}>
          <img src={`/uploads/featured-in/${logo}.png`} alt={logo} />
        </div>
      ))}
    </div>
  </div>
)
