import React, { useState, useEffect } from 'react'
import slugify from 'slugify'
import { navigate } from 'gatsby'
import Image from '../Image'

import classNames from 'classnames'
import styles from './job-application.module.sass'

export default function JobApplication({ title, jobTitle, className, card, jobs }) {
  const [data, setData] = useState({
    subject: 'SoftKraft application',
    path: '/contact/',
    job: jobTitle || jobs[0].title || ''
  })
  const [formState, setFormState] = useState('')

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('applicationData'))
    if (storedData && storedData.expiry && Date.now() < storedData.expiry) {
      setData(storedData.data)
    } else {
      localStorage.removeItem('applicationData')
    }

    document.querySelector('[href="#job-application"]').addEventListener('click', e => {
      e.preventDefault()
      document.querySelector('#job-application').scrollIntoView({
        behavior: 'smooth'
      })
    })
  }, [])

  useEffect(() => {
    const expiryTime = Date.now() + 60 * 60 * 1000
    localStorage.setItem('applicationData', JSON.stringify({ data, expiry: expiryTime }))
  }, [data])

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormState('loading')

    const form = e.target
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })

    fetch('/', {
      body: formData,
      method: 'POST'
    })
      .then(() => {
        localStorage.removeItem('entry')
        localStorage.removeItem('applicationData')
        setFormState('success')
        navigate(form.getAttribute('action'))
      })
      .catch(error => setFormState('error'))
  }

  const handleSelect = e => {
    setData({ ...data, job: e.target.value })
    navigate(`/career/${slugify(e.target.value, { lower: true })}/`)
  }

  const handleName = e => {
    setData({ ...data, subject: `SoftKraft enquiry from ${e.target.value}`, name: e.target.value })
  }

  const bytesToSize = bytes => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 0) + ' ' + sizes[i]
  }

  const handleFile = e => {
    const file = e.target.files[0]
    const fileAttached = e.target.parentNode.querySelector('span')
    fileAttached.style.display = file ? 'block' : 'none'
    if (file) {
      fileAttached.childNodes[0].textContent = file.name
      fileAttached.dataset.size = bytesToSize(file.size)
    }
    setData({ ...data, file })
  }

  const removeFile = e => {
    e.preventDefault()
    const fileInput = e.target.form.elements.file
    const fileAttached = e.target.parentNode
    fileAttached.style.display = 'none'
    fileInput.files = null
    setData({ ...data, file: {} })
  }

  return (
    <form
      name="Application"
      method="post"
      action="/career/thank-you/"
      encType="multipart/form-data"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className={classNames(styles.jobApplication, className, card ? styles.card : styles.block)}
    >
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <div className={styles.formHeader}>
        <h2>{title || 'Apply to this job in one step'}</h2>
        <p className={styles.formField}>
          {!card && (
            <label className={styles.formLabel} htmlFor="job">
              Choose a job offer
            </label>
          )}
          <select name="job" defaultValue={data.job} id="job" required onChange={handleSelect}>
            {jobs.map(({ title }) => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
          </select>
        </p>
        <figure className={styles.headerBg}>
          <Image src={'/images/pages/services/it-staff-augmentation.png'} />
        </figure>
      </div>
      <div className={styles.formFields}>
        <p className={classNames(styles.formField, 'short', data.name && 'active')}>
          <label htmlFor="name">Your name</label>
          <input type="text" name="name" id="name" defaultValue={data.name} onChange={handleName} required />
        </p>
        <p className={classNames(styles.formField, 'short', data.email && 'active')}>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" defaultValue={data.email} onChange={handleChange} required />
        </p>
        <p className={classNames(styles.formField, data.message && 'active')}>
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows={4} defaultValue={data.message} onChange={handleChange} required />
        </p>
        <p className={classNames(styles.formField, 'file')}>
          <input type="file" name="file" id="file" onChange={handleFile} />
          <label htmlFor="file" className={styles.fileLabel}>
            Attach your CV
          </label>
          <span className={styles.fileAttached} data-size="0 kB">
            No file selected <button onClick={removeFile}>Remove</button>
          </span>
        </p>
        <p className={styles.formField}>
          <input type="checkbox" id="privacy" name="privacy" defaultChecked={data.privacy} onChange={handleChange} required />
          <label htmlFor="privacy" className={styles.checkLabel}>
            I consent processing my personal data according to the{' '}
            <a href="/privacy/" target="_blank">
              Privacy Policy
            </a>
          </label>
        </p>
        <p className={styles.formField}>
          <input type="checkbox" id="rodo" name="rodo" defaultChecked={data.rodo} onChange={handleChange} required />
          <label htmlFor="rodo" className={styles.checkLabel}>
            I consent to the{' '}
            <a href="/rodo/" target="_blank">
              RODO Conditions
            </a>
          </label>
        </p>
        <p className={classNames(styles.formField, 'submit')}>
          <button disabled={formState === 'loading'} className={styles.formSubmit}>
            {formState === 'loading' ? 'Sending...' : 'Submit application'}
          </button>
        </p>
      </div>

      <div className={styles.formAnhor}>
        <a href="#job-application" className={styles.formSubmit}>
          Submit application
        </a>
      </div>
    </form>
  )
}
