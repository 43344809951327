import React from 'react'
import Markdown from 'markdown-to-jsx'
import StepperInput from '../Forms/StepperInput'
import EstimationForm from '../Forms/EstimationForm'
// import kebabCase from 'lodash/kebabCase'
import Modal from './Modal'
import { getAllKeys, findValue } from '../utils'
import get from 'lodash/get'
import classNames from 'classnames'
import styles from './estimation-modal.module.sass'

const Data = ({ data, value }) => <span>{data[value]}</span>

const SummaryList = ({ estimation, data, setData }) => (
  <ul className={styles.summaryList}>
    {estimation.fields.map(
      field =>
        field.summary &&
        (field.type === 'stepper' ? getAllKeys(field.inputs) : [field.name]).map(input =>
          get(data, [field.name, input]) || data[input] ? (
            <Markdown
              className={classNames(styles.summaryItem, styles[field.type])}
              options={{
                wrapper: 'li',
                forceWrapper: true,
                overrides: {
                  StepperInput: {
                    component: StepperInput,
                    props: { label: input, name: field.name, input: input, info: findValue(field.inputs, input), data: data, setData: setData }
                  },
                  Data: { component: Data, props: { data: data } }
                }
              }}
            >
              {field.summary}
            </Markdown>
          ) : null
        )
    )}
  </ul>
)

export default function ProjectModal({ estimation, data, setData }) {
  return (
    <Modal className={styles.estimationModal} setName={''} name={estimation.name}>
      <EstimationForm estimation={estimation} data={data} setData={setData} />
      <Markdown
        className={styles.estimationSummary}
        options={{
          forceBlock: true,
          overrides: {
            SummaryList: { component: SummaryList, props: { estimation: estimation, data: data, setData: setData } },
            Data: { component: Data, props: { data: data } }
          }
        }}
      >
        {estimation.summaryContent}
      </Markdown>
    </Modal>
  )
}
