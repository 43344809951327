import React from 'react'
import loadable from '@loadable/component'
import { parameterize } from '../utils'

import PageContent from '../content/PageContent'
import Section from '../Section'
// import ContactForm from '../ContactForm/ContactForm'
import Columns from '../Columns'
// import Testimonials from '../Testimonials'
// import Profiles from '../Profiles'
// import Team from '../Team'
// import Numbers from '../Numbers'
// import Steps from '../Steps'
// import Projects from '../Projects'
// import Logos from '../Logos'
import Markdown from 'markdown-to-jsx'
import { Technologies } from '../Sections/TextSection'
import ImageSection from '../ImageSection'
import Expert from '../Expert'
import ImagesLine from '../ImagesLine'
import Aside from '../Aside'
import StepsSecton from '../Sections/StepsSection'
import TextSection from '../Sections/TextSection'
import ServiceSection from '../Sections/ServiceSection'
import ServicesSection from '../Sections/ServicesSection'
// import ValueTrust from '../Sections/ValueTrust'
import Logos from '../Blocks/Logos'
import NumbersSection from '../Sections/NumbersSection'
import PageTabs from '../Sections/PageTabs'
import ProfilePosts from '../Profiles/ProfilePosts'
import ProjectPhase from '../Blocks/ProjectPhase'
import ProjectEstimation from '../Blocks/ProjectEstimation'
import SectionWrapper from '../Sections/SectionWrapper'
import JobCarousel from '../Career/JobCarousel'
import ApplicationSection from '../Career/ApplicationSection'
import TestimonialSection from '../Sections/TestimonialSection'
import AffixNav from '../Blocks/AffixNav'
import FaqSection from '../Sections/FaqSection'
import BlogpostsSection from '../Sections/BlogpostsSection'
import ContactExpert from '../ContactForm/ContactExpert'
import BlockSlider from '../Sections/BlockSlider'
import ChainSlider from '../Wrappers/ChainSlider'
// const LazyBlockSlider = loadable(() => import('../Sections/BlockSlider'))
// const BlockSlider = props => <LazyBlockSlider {...props} />
// import Calendly from '../Widgets/Calendly'
// const LazyCalendly = loadable(() => import('../Widgets/Calendly'))
// const Calendly = props => <LazyCalendly {...props} />
// import TeamCarousel from '../Sections/TeamCarousel'
const LazyTeamCarousel = loadable(() => import('../Sections/TeamCarousel'))
const TeamCarousel = props => <LazyTeamCarousel {...props} />
// const LazyJobCarousel = loadable(() => import('../Career/JobCarousel'))
// const JobCarousel = props => <LazyJobCarousel {...props} />
// import ImageCarousel from '../Wrappers/ImageCarousel'
const LazyImageCarousel = loadable(() => import('../Wrappers/ImageCarousel'))
const ImageCarousel = props => <LazyImageCarousel {...props} />
// import ImageSlider from '../Sections/ImageSlider'
const LazyImageSlider = loadable(() => import('../Sections/ImageSlider'))
const ImageSlider = props => <LazyImageSlider {...props} />
// import StrengthsSection from '../Sections/StrengthsSection'
const LazyStrengthsSection = loadable(() => import('../Sections/StrengthsSection'))
const StrengthsSection = props => <LazyStrengthsSection {...props} />
// const LazyFaqSection = loadable(() => import('../Sections/FaqSection'))
// const FaqSection = props => <LazyFaqSection {...props} />
// import ProjectsSection from '../Sections/ProjectsSection'
const LazyProjectsSection = loadable(() => import('../Sections/ProjectsSection'))
const ProjectsSection = props => <LazyProjectsSection {...props} />

export default ({ section, images, sections }) => {
  if (section.type === 'text') {
    return (
      <Section key={`section-${section.id}`} {...section}>
        {section.aside ? (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Aside {...section.aside} />
            </div>
            <div className="col-md-6 col-sm-12">
              <PageContent children={section.text} />
            </div>
          </div>
        ) : (
          <PageContent children={section.text} />
        )}
      </Section>
    )
  }
  if (section.type === 'image') {
    return (
      <>
        <ImageSection image={section.image} key={`section-${section.id}`} {...section} />
      </>
    )
  }
  // if (section.type === 'projects') {
  //   return (
  //     <Section key={`section-${section.id}`} {...section}>
  //       {section.text && <PageContent children={section.text} />}
  //       <Projects projects={section.projects} />
  //     </Section>
  //   )
  // }
  if (section.type === 'columns') {
    return (
      <Section key={`section-${section.id}`} {...section}>
        {section.text && <PageContent children={section.text} />}
        <Columns {...section} />
      </Section>
    )
  }
  if (section.type === 'imagesline') {
    return (
      <section className={section.classes}>
        <ImagesLine images={section.images} />
      </section>
    )
  }
  if (section.type === 'expert') {
    return (
      <Section key={`section-${section.id}`} {...section}>
        <Expert {...section} />
      </Section>
    )
  }

  switch (section.type) {
    case 'affixNav':
      return <AffixNav key={section.id} sections={sections} />

    case 'pageTabs':
      return <PageTabs key={section.id} tabs={section.tabs} />

    case 'strengthsSection':
      return <StrengthsSection key={section.id} {...section} />

    case 'contactExpert':
      return <ContactExpert key={section.id} {...section} />

    case 'profiles':
      return <TeamCarousel key={section.id} filters={section.filters} profiles={section.profiles} {...section} />

    case 'jobs':
      return <JobCarousel key={section.id} {...section} />

    case 'application':
      return <ApplicationSection key={section.id} {...section} />

    case 'servicesSlider':
      return <BlockSlider key={section.id} blocks={section.groups} {...section} />

    case 'stepsSection':
      return <StepsSecton key={section.id} {...section} />

    case 'textSection':
      return <TextSection key={section.id} {...section} images={images} />

    case 'testimonials':
    case 'testimonialSection':
      return <TestimonialSection key={section.id} {...section} />

    case 'serviceSection':
      return <ServiceSection key={section.id} {...section} images={images} />

    case 'projectPhase':
      return (
        <section key={section.id} id={parameterize(section.affix) || section.id} className={section.classes || `overflow-hidden bg-light`}>
          <div className="container position-relative">
            <ProjectPhase {...section} images={images} />
          </div>
        </section>
      )

    case 'chainSlider':
      return (
        <section key={section.id} className="overflow-hidden bg-light py-four">
          <div className="container is-wide position-relative">
            <ChainSlider {...section}>{section.content}</ChainSlider>
          </div>
        </section>
      )

    case 'servicesSection':
      return <ServicesSection key={section.id} {...section} />

    case 'projectsSection':
      return <ProjectsSection key={section.id} {...section} />

    case 'imageSlider':
      return <ImageSlider key={section.id} images={section.images} />

    case 'projectEstimation':
      return (
        <SectionWrapper key={section.id} {...section}>
          <ProjectEstimation {...section} />
        </SectionWrapper>
      )

    case 'carousel':
      return (
        <section key={section.id} className={section.classes}>
          <ImageCarousel images={section.images} />
        </section>
      )
    case 'questions':
      return <FaqSection key={section.id} {...section} />
    case 'blogposts':
      return <BlogpostsSection key={section.id} {...section} />
    case 'logos':
      return (
        <section key={section.id} className={section.classes}>
          <div className="container">
            <Logos logos={section.logos} />
          </div>
        </section>
      )
    case 'numbersSection':
      return <NumbersSection key={section.id} {...section} />

    case 'profilePosts':
      return <ProfilePosts key={section.id} {...section} />

    default:
      return <Section key={section.id}>{JSON.stringify(section)}</Section>
  }
}
// if (section.type === 'calendly') {
//   return (
//     <Section key={`section-${section.id}`} {...section}>
//       <Calendly>{section.text && <PageContent children={section.text} />}</Calendly>
//     </Section>
//   )
// }
