import React, { useContext } from 'react'
import ReactModal from 'react-modal'
import { ModalContext } from '../Modals/Modal'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import styles from './calendly-modal.module.sass'

export default function CalendlyModal({ url }) {
  const { activeModal, setActiveModal } = useContext(ModalContext)

  function afterOpenModal() {
    disableBodyScroll(document.querySelector('.ReactModal__Overlay'))
  }

  function closeModal() {
    enableBodyScroll(document.querySelector('.ReactModal__Overlay'))
    setActiveModal(null)
  }

  const calendlyEventHandler = name => () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: name,
      formName: 'Calendly'
    })
    // console.log('calendly event: ' + name)
    return true
  }

  useCalendlyEventListener({
    onEventTypeViewed: calendlyEventHandler('calendly_viewed'),
    onDateAndTimeSelected: calendlyEventHandler('calendly_selected'),
    onEventScheduled: calendlyEventHandler('calendly_scheduled'),
  });

  return (
    <ReactModal
      overlayClassName={styles.modalOverlay}
      className={styles.calendlyModal}
      isOpen={activeModal === 'calendly-modal'}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="modal"
      ariaHideApp={false}
      closeTimeoutMS={500}
      id="calendly-modal"
    >
      <div className={styles.modalContent}>
        <InlineWidget
          url={url}
          styles={{
            height: '695px'
          }}
        />
      </div>
      <button className={styles.closeButton} data-dismiss="modal" aria-label="Close" onClick={closeModal}>
        Close
      </button>
    </ReactModal>
  )
}
