import React, { useState, useEffect } from 'react'
import Markdown, { compiler } from 'markdown-to-jsx'
import { navigate } from 'gatsby'
import { isSpam } from '../../utils/tracking'
import styles from './estimation-form.module.sass'
import { getAllKeys } from '../utils'
import get from 'lodash/get'
import classNames from 'classnames'

const SummaryList = (estimation, data) =>
  estimation.fields
    .filter(f => f.summary)
    .map(field =>
      (field.type === 'stepper' ? getAllKeys(field.inputs) : [field.name])
        .map(
          input =>
            (get(data, [field.name, input]) || data[input]) &&
            compiler(field.summary, {
              createElement(type, props, children) {
                switch (type) {
                  case 'Data':
                    return data[props.value]
                  case 'span':
                    return children
                  case 'StepperInput':
                    return `${get(data, [field.name, input], 0)} x ${input}`
                  default:
                    return ''
                }
              }
            })
        )
        .join('')
    )
    .join('\n')

const MessageSummary = (estimation, data) =>
  compiler(estimation.summaryContent, {
    createElement(type, props, children) {
      switch (type) {
        case 'h2':
        case 'p':
          return `${children}\n`
        case 'span':
          return children
        case 'Data':
          return data[props.value]
        case 'SummaryList':
          return SummaryList(estimation, data)
        default:
          return ''
      }
    }
  })

export default function EstimationForm({ estimation, data, setData }) {
  const [formState, setFormState] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setData({ ...data, path: localStorage.entry || window.location.path })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'form_start',
        formName: estimation.name
      })
    }
  }, [])

  // useEffect(() => {
  //   // console.log(SummaryList(estimation, data))
  //   console.log(data)
  // }, [data])

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isSpam(data.message)) {
      navigate('/contact/submitted/')
    }

    const form = e.target
    setFormState('loading')

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': estimation.name,
        ...data,
        message: data.message + '\n\n' + MessageSummary(estimation, data)
      }).toString()
    })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'form_sent',
          formName: estimation.name,
          email: data.email
        })

        localStorage.removeItem('entry')
        setFormState('success')
        navigate(form.getAttribute('action'))
      })
      .catch(error => setFormState('error'))
  }

  const handleInput = e => {
    handleChange(e)
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  const handleName = e => {
    setData({ ...data, subject: `SoftKraft enquiry from ${e.target.value}`, name: e.target.value })
    e.target.parentNode.classList.toggle('active', e.target.value)
  }

  return (
    <form
      name={estimation.name}
      method="post"
      action="/contact/thank-you/"
      data-netlify="true"
      // data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className={styles.estimationForm}
    >
      <p hidden>
        {/* <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label> */}
        <input type="hidden" name="form-name" />
        <input type="hidden" name="subject" />
        <input type="hidden" name="path" />
      </p>
      <Markdown className={styles.formContent}>{estimation.formContent}</Markdown>
      <p className={styles.formField}>
        <label htmlFor="name">Your name</label>
        <input type="text" name="name" id="name" required onChange={handleName} />
      </p>
      <p className={styles.formField}>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" required onChange={handleInput} />
      </p>
      <p className={styles.formField}>
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" required rows={7} onChange={handleInput} />
      </p>
      <p className={styles.formField}>
        <input type="checkbox" required name="nda" id="nda" />
        <label htmlFor="nda">I want to protect my data by signing an NDA </label>
      </p>
      <p className={classNames(styles.formField, 'submit')}>
        <button className={styles.formSubmit}>Send</button>
      </p>
    </form>
  )
}
