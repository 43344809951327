import React, { useState } from 'react'
import loadable from '@loadable/component'

import { graphql, useStaticQuery } from 'gatsby'

import TestimonialCard from '../CardItems/TestimonialCard'
import Logos from '../Blocks/Logos'

import Markdown from 'markdown-to-jsx'
import Button from '../Inlines/Button'
import Accent from '../Inlines/Accent'

import classNames from 'classnames'
import styles from './testimonial-section.module.sass'

// import Filters from '../Blocks/Filters'
const LazyFilters = loadable(() => import('../Blocks/Filters'))
const Filters = props => <LazyFilters {...props} />
// import TestimonialCarousel from '../Blocks/TestimonialCarousel'
const LazyTestimonialCarousel = loadable(() => import('../Blocks/TestimonialCarousel'))
const TestimonialCarousel = props => <LazyTestimonialCarousel {...props} />

export default function TestimonialSection({ testimonialTags, testimonials, classes, header, logos, footer }) {
  const data = useStaticQuery(graphql`
    query allTestimonialsQuery {
      testimonials: allTestimonialsYaml(limit: 1000) {
        nodes {
          background
          text
          image
          name
          position
          tab
          tags
        }
      }
      people: allPeopleYaml(filter: { avatar: { ne: null } }) {
        nodes {
          id
          name
          position
          avatar
        }
      }
    }
  `)

  const people = data.people.nodes

  testimonials = (testimonials || data.testimonials.nodes).map(testimonial => {
    const person = people.find(person => person.id === testimonial.person)
    if (person) {
      return { ...testimonial, image: person.avatar || person.image, name: person.name, position: person.position }
    }
    return testimonial
  })

  const [testimonialFilter, setTestimonialFilter] = useState(testimonialTags ? 'Featured' : null)
  const matchTags = (tags, filter) => {
    // console.log(tags, filter)
    return [filter].filter(Boolean).every(filter => (tags || []).includes(filter))
  }

  return (
    <section className={classNames(styles.testimonialSection, classes)}>
      <div className="container">
        <Markdown className={styles.sectionHeader} children={header || ''} options={{ forceWrapper: true, overrides: { Accent } }} />
        <div>
          {testimonialTags && <Filters tags={testimonialTags} filter={testimonialFilter} setFilter={setTestimonialFilter} />}
          <TestimonialCarousel>
            {testimonials
              .filter(testimonial => (testimonialTags ? matchTags(testimonial.tags, testimonialFilter) : testimonial))
              .map((testimonial, i) => (
                <TestimonialCard key={i} {...testimonial} />
              ))}
          </TestimonialCarousel>
        </div>
        {logos && (
          <div className="row mt-4">
            <Logos logos={logos} customStyles="col" />
          </div>
        )}
        <Markdown className={styles.sectionFooter} children={footer || ''} options={{ forceWrapper: true, forceBlock: true, overrides: { Button } }} />
      </div>
    </section>
  )
}
