import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Icon from '../Icon'
import Image from '../Image'
import ArrowRightIcon from '../../../static/uploads/icons/icon_arrow-right.inline.svg'
import Accent from '../Inlines/Accent'

export default ({ columns, header, aside }) => (
  <>
    {header && (
      <div className="header">
        <Markdown children={header} options={{ overrides: { Accent } }} />
      </div>
    )}
    <div className="column-container">
      {aside && <aside className="aside">{aside}</aside>}
      <div className="row">
        {columns.map((column, i) => (
          <div className="col" key={i}>
            {column.link ? (
              <Link to={column.link} className="link-unstyled">
                <div className={classNames('box', column.classes)}>
                  {column.icon && <Icon name={column.icon} />}
                  <div className="box-content">
                    <Markdown children={column.text} />
                    <p className="box-link">
                      learn more
                      <ArrowRightIcon />
                    </p>
                  </div>
                </div>
              </Link>
            ) : (
              <div className={classNames('box', column.classes)}>
                {column.icon && <Icon name={column.icon} />}
                {column.image && <Image src={column.image} />}
                <div className="box-content">
                  <Markdown
                    children={column.text}
                    options={{
                      wrapper: function none({ children }) {
                        return children
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </>
)
