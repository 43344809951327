import React from 'react'
import Markdown from 'markdown-to-jsx'
import styles from './steps-section.module.sass'
import classNames from 'classnames'
import Button from '../Inlines/Button'
import Accent from '../Inlines/Accent'

export default function StepsSection({ header, steps, footer, classes }) {
  return (
    <section className={classNames(styles.stepsSection, classes)}>
      <div className="container">
        {header && header.content && (
          <header className={classNames(styles.sectionHeader, header.classes)}>
            <Markdown children={header.content} options={{ overrides: { Accent } }} />
          </header>
        )}
        {steps && (
          <div className={styles.sectionContent}>
            <ul className={styles.stepList}>
              {steps.map((step, index) => (
                <li className={styles.stepItem} key={`number-${index}`}>
                  <span className={styles.stepNumber}>0{index + 1}</span>
                  <Markdown className={styles.stepText}>{step.text}</Markdown>
                </li>
              ))}
            </ul>
          </div>
        )}
        {footer && footer.content && (
          <div className={classNames(styles.sectionFooter, footer.classes)}>
            <Markdown children={footer.content} options={{ overrides: { Button } }} />
          </div>
        )}
      </div>
    </section>
  )
}
