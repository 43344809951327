import React, { useState } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import find from 'lodash/find'

const HeroNav = props => {
  const {
    name,
    className,
    allNavbars: { nodes: navbars }
  } = props
  const [collapsed, setCollapsed] = useState(false)
  const { items = [] } = find(navbars, ['name', name])

  if (!items) {
    return null
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark hero--nav justify-content-center mb-2">
      <button
        className={classNames(`navbar-toggler ${collapsed ? 'navbar-toggler-fixed' : null}`)}
        type="button"
        data-toggle="collapse"
        data-target="#heroNav"
        aria-controls="heroNav"
        aria-expanded={collapsed}
        aria-label="Toggle navigation"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div
        className={classNames(`collapse navbar-collapse justify-content-center ${className}`, {
          show: collapsed
        })}
        id="heroNav"
      >
        <ul className={classNames(`navbar-nav hero--list justify-content-center`)}>
          {items.map(({ label, link }) => (
            <li className="nav-item" key={link}>
              <Link className="nav-link" to={link} activeClassName="active">
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allNavbars: allNavbarsYaml {
          totalCount
          nodes {
            name
            items {
              label
              link
            }
          }
        }
      }
    `}
    render={data => <HeroNav {...props} {...data} />}
  />
)
