import React from 'react'
import classNames from 'classnames'
import styles from './section-wrapper.module.sass'

export default function SectionWrapper({ children, classes, wrapperClasses }) {
  return (
    <section className={classNames(styles.sectionWrapper, classes)}>
      <div className={classNames('container', wrapperClasses)}>{children}</div>
    </section>
  )
}
