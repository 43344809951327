import React from 'react'
import PageContent from '../content/PageContent'

export default ({ value, image, expert }) => {
  return (
    <div className="expert-container">
      <div className="expert-container__value">
        <div>
          <PageContent children={value} />
        </div>
        {image && (
          <div className="image-container">
            <img className="about-image" src={image} alt="" />
          </div>
        )}
      </div>
      <hr className="line" />
      <div className="expert-container__about">{expert && <PageContent children={expert} />}</div>
    </div>
  )
}
