import React from 'react'
import loadable from '@loadable/component'

import classNames from 'classnames'

import PageContent from '../content/PageContent'
// import VideoOrImage from '../VideoOrImage'
// const LazyVideoOrImage = loadable(() => import('../VideoOrImage'))
// const VideoOrImage = props => <LazyVideoOrImage {...props} />
import Image from '../Image'
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)

const LazyModalVideoButton = loadable(() => import('../Modals/ModalVideoButton'))
const ModalVideoButton = props => <LazyModalVideoButton {...props} />

export default ({ classes, text, image, video, background }) => (
  <div className="aside">
    {image && (
      <ConditionalWrapper condition={video} wrapper={children => <ModalVideoButton video={video}>{children}</ModalVideoButton>}>
        <Image src={image} width={!video && '70%'} />
      </ConditionalWrapper>
    )}
    <div className={classNames(`p-5 ${classes}`)}>{text && <PageContent children={text} />}</div>
  </div>
)
