import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Link, graphql, StaticQuery } from 'gatsby'
import styles from './blogposts-section.module.sass'
import classNames from 'classnames'
import keyBy from 'lodash/keyBy'
import Accent from '../Inlines/Accent'
import CaseGrid from '../Wrappers/CaseGrid'
import PostCard from '../CardItems/PostCard'

const BlogpostSection = ({ blogposts = [], text, classes, allPosts: { nodes: allPosts } }) => {
  const postsByPath = keyBy(allPosts, 'path')
  const postList = blogposts.map(post => postsByPath[post])

  return (
    <section className={classNames(styles.blogpostsSection, classes)}>
      <div className="container">
        <Markdown
          className={styles.sectionHeader} children={text || ''}
          options={{ forceWrapper: true, overrides: { Accent } }}
        />
        <CaseGrid>
          {postList.map(({ id, path, title, thumb }) =>
            <PostCard key={id} id={id} path={path} title={title} image={thumb} />
          )}
        </CaseGrid>
      </div>
    </section>
  )
}

export default ({ blogposts, text, classes }) => (
  <StaticQuery
    query={graphql`
      {
        allPosts: allPostsYaml(sort: { fields: [date], order: DESC }) {
          totalCount
          nodes {
            id
            path
            title
            thumb
          }
        }
      }
    `}
    render={data => <BlogpostSection {...data} blogposts={blogposts} text={text} classes={classes} />}
  />
)
