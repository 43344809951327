import React, { useEffect, useRef } from 'react'

import styles from './duration-input.module.sass'

export default function DurationInput({ name, data, setData }) {
  const rangeEl = useRef(null)
  const outputEl = useRef(null)

  const handleChange = e => {
    const { value } = e.target
    setData(current => ({ ...current, duration: stringPluralize(value, 'month', 's') }))
  }

  const stringPluralize = (count, str, suffix = 's', includeCount = true) => {
    let res = `${str}${count > 1 ? suffix : ''}`
    if (includeCount == true) return `${count} ${res}`
    return res
  }

  function update(range, bubble) {
    const val = range.value
    const min = range.min ? range.min : 0
    const max = range.max ? range.max : 100
    const newVal = Number(((val - min) * 100) / (max - min))

    bubble.innerHTML = stringPluralize(val, 'month', 's')
    bubble.style.left = `calc(${newVal}% + (${10 - newVal * 0.21}px))`

    const bgPosition = 100 - ((val - min) / (max - min)) * 100
    range.style.backgroundPosition = `${bgPosition}% 0%`

    Array.prototype.filter.call(range.parentNode.querySelectorAll('option'), function(node) {
      node.classList.toggle('active', Number(node.value) <= val)
    })
  }

  useEffect(() => {
    rangeEl.current.addEventListener('input', () => {
      update(rangeEl.current, outputEl.current)
    })
    update(rangeEl.current, outputEl.current)
  }, [])

  return (
    <p className={styles.durationInput}>
      <input type="range" name={name} id={name} min={1} max={24} defaultValue={1} onChange={handleChange} ref={rangeEl} />
      <output ref={outputEl}>{data[name]}</output>
      <datalist id="values">
        <option value="1" label="1 month" />
        <option value="6" label="6 months" />
        <option value="12" label="1 year" />
        <option value="18" label="1.5 year" />
        <option value="24" label="2 years" />
      </datalist>
    </p>
  )
}
