import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import JobApplication from './JobApplication'
import styles from './application-section.module.sass'

export default function ApplicationSection() {
  const data = useStaticQuery(graphql`
    query jobTitles {
      jobs: allJobPostsYaml(filter: { active: { ne: false } }) {
        nodes {
          title
          path
        }
      }
    }
  `)

  if (data.jobs.nodes.length === 0) {
    return null
  }

  return (
    <section className={styles.applicationSection} id="job-application">
      <div className="container">
        <JobApplication title="Apply to SoftKraft in one step" jobs={data.jobs.nodes} />
      </div>
    </section>
  )
}
