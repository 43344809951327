import React, { useRef, useState, useEffect } from 'react'
import { parameterize, debounce } from '../utils'
// import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'

import classNames from 'classnames'
// import magicLine from '../ui/magicLine.js'
import styles from './affix-nav.module.sass'

export default function AffixNav({ sections }) {
  const ref = useRef(null)
  const [activeSection, setActiveSection] = useState('services-overview')
  const affixSections = [{ affix: 'Services Overview' }]
    .concat(sections.filter(section => section.affix))
    .map(section => ({ id: section.id || parameterize(section.affix), affix: section.affix }))
  const [isVisible, setIsVisible] = useState(false)
  // const [onPrimary, setOnPrimary] = useState('before')

  useEffect(() => {
    // new magicLine(ref.current, styles.navLinkActive)

    const handleScroll = () => {
      const firstSection = document.querySelector('section:first-of-type')
      const primary = ref.current.parentNode.querySelector('#contact')

      if (primary && firstSection) {
        setIsVisible(firstSection.getBoundingClientRect().bottom <= 0 && primary.getBoundingClientRect().top - 10 > 0)
      }

      // console.log(primary.getBoundingClientRect().top + primary.clientHeight)
      // if (primary.getBoundingClientRect().top - ref.current.clientHeight > 0) {
      //   setOnPrimary('before')
      // } else if (primary.getBoundingClientRect().top + primary.clientHeight - ref.current.clientHeight > 0) {
      //   setOnPrimary('over')
      // } else {
      //   setOnPrimary('after')
      // }

      // console.log(ref.current.nextElementSibling.getBoundingClientRect().top)

      const visibleSections = affixSections.filter(section => {
        const element = document.getElementById(section.id)
        const rect = element && element.getBoundingClientRect()
        if (rect && rect.top < window.innerHeight / 2) {
          return true
        }
        return false
      })

      if (visibleSections.length) {
        setActiveSection(visibleSections[visibleSections.length - 1].id)
        // console.log(visibleSections, activeSection)
      }
    }

    const debouncedHandleScroll = debounce(handleScroll, 40)
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => window.removeEventListener('scroll', debouncedHandleScroll)
  }, [])

  // useEffect(() => {
  //   ref.current.magicLine.updateActive(ref.current, styles.navLinkActive)
  //   ref.current.magicLine.update()
  //   // window.history.pushState({}, '', `#${activeSection}`)
  // }, [activeSection])

  return (
    <ul className={classNames('affix-nav', styles.affixNav, { 'is-visible': isVisible })} id="services-overview" ref={ref}>
      {affixSections.map(section => (
        <li key={section.id} className={classNames(styles.navItem, { [styles.navItemActive]: activeSection === section.id })}>
          <a
            href={`#${parameterize(section.affix) || section.id}`}
            className={classNames(styles.navLink, { [styles.navLinkActive]: activeSection === section.id })}
            onClick={e => {
              e.preventDefault()
              setActiveSection(section.id)
              document.scrollingElement.onClickScroll = true
              document.addEventListener(
                'scrollend',
                function() {
                  document.scrollingElement.onClickScroll = false
                },
                { once: true }
              )
              ;(document.getElementById(section.id) === ref.current
                ? document.getElementById(section.id).nextElementSibling
                : document.getElementById(section.id)
              ).scrollIntoView({
                behavior: 'smooth'
              })
            }}
            data={section.affix}
          >
            {section.affix}
            {/* {section.affix.match('Contact') && <ArrowIcon />} */}
          </a>
        </li>
      ))}
    </ul>
  )
}

// const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
//   const [isSticky, setIsSticky] = useState(false)
//   const newRef = useRef()
//   ref ||= newRef

//   // mount
//   useEffect(() => {
//     const cachedRef = ref.current,
//       observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), observerSettings)

//     observer.observe(cachedRef)

//     return () => {
//       observer.unobserve(cachedRef)
//     }
//   }, [])

//   return [isSticky, ref, setIsSticky]
// }
